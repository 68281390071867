import React, { useEffect, useMemo, useState } from "react";
import { Container, Fab } from "@material-ui/core";
import FileUploader from "../Components/VideoUploader";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import videocamera from "../assets/videocamera.jpg";
import VideoList from "../Components/VideoList";
import AddIcon from "@material-ui/icons/Add";

import { useSelector, useDispatch } from "react-redux";
import {
  videoModalRequest,
  getVideoRequest,
  getTopCarouselDataRequest,
  patchOrderTopCarouselDataRequest,
  patchTopCarouselDataRequest,
  deleteTopCarouselDataRequest,
  setModalDataRequest,
} from "../redux/actions";
import Loader from "../Components/Loader";
import Error from "../Components/Error";
import { Document, Page, pdfjs } from "react-pdf";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CarouselItem from "../Components/CarouselItem";
import TopCarouselUploader from "../Components/TopCarouselUploader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { io } from "socket.io-client";
// var socket = require('socket.io-client')('http://ec2-13-233-245-254.ap-south-1.compute.amazonaws.com:3000/', {transports: ['polling']});

// socket.on('connect', function(){
//     console.log("connection made")
// });
// socket.on('videoProgress', function(data){
//     console.log("video upload progress: ",data);
// });
// socket.on('disconnect', function(){
//     console.log("disconnected...")
// });

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
  paperOne: {
    padding: "40px 60px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    position: "fixed",
    top: "30vh",
  },
  container: {
    // height:'100%',
    overflowY: "hidden",
  },
  childContainer: {
    height: "inherit",
  },
  paperWrapper: {
    height: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    outline: "none",
    border: "none",
    background: "#065fd4",
    color: "#fff",
    padding: 10,
    cursor: "pointer",
  },
  title: {
    fontWeight: 700,
    color: "#000000",
  },
  description: {
    margin: "6px 0px 35px 0px",
    fontSize: ".8rem",
  },
  videocamera: {
    width: 120,
    height: 120,
    borderRadius: 60,
    marginBottom: 35,
  },
  paperWrapperTwo: {
    // height:'95%'
  },
  paperTwo: {
    minHeight: "85vh",
    padding: 10,
    margin: 10,
  },
  loaderWrapper: {
    // height:'100%',
  },
}));

export default function TopCarousel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const videoModal = useSelector((state) => state.config.videoModal.isVisible);
  const topCarousel = useSelector((state) => state.course.topCarousel);
  const [mediaList, setMediaList] = useState([]);
  useMemo(() => {
    if (
      topCarousel.data &&
      Array.isArray(topCarousel.data) &&
      topCarousel.data.length > 0
    ) {
      setMediaList(topCarousel.data);
    }
  }, [topCarousel]);
  useEffect(() => {
    dispatch(getTopCarouselDataRequest());
  }, []);

  const handleOpenModal = () => {
    dispatch(videoModalRequest(true));
  };

  const handleEditMedia = (item) => {
    dispatch(setModalDataRequest(item));
    dispatch(videoModalRequest(true));
  };

  const handleDeleteImage = (id) => {
    dispatch(deleteTopCarouselDataRequest({ _id: id }));
    // console.log("Delete image", id);
  };

  const handleChangeImageVisibility = (data) => {
    const newData = { appearInCarousel: !data.appearInCarousel };

    dispatch(
      patchTopCarouselDataRequest({
        payload: newData,
        _id: data._id,
        typeUpdate: "visibility",
      })
    );
  };
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list
    if (!destination) return;

    if (destination.index === source.index) return;

    // Reorder the items
    const reorderedItems = [...mediaList];
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    const newOrder = reorderedItems.map((item, index) => {
      return { _id: item._id, sort_index: index };
    });

    dispatch(patchOrderTopCarouselDataRequest({ order: newOrder }));
    setMediaList(reorderedItems);
  };
  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.playlistTitle}>Top Carousel</div>
      </div>
      <TopCarouselUploader />
      {topCarousel.isLoading && <Loader variant="big" />}
      {topCarousel.isError && <Error message={topCarousel.message} />}
      {topCarousel.isSuccess && mediaList.length > 0 && (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={`top-carousel-dropable`}>
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {mediaList.map((item, index) => {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <CarouselItem
                                key={index}
                                data={item}
                                handleDelete={handleDeleteImage}
                                handleChangeVisibility={
                                  handleChangeImageVisibility
                                }
                                showEdit={true}
                                handleEdit={handleEditMedia}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </>
      )}
      <Fab
        size="small"
        onClick={handleOpenModal}
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
