import logo from "./logo.svg";
import "./App.css";
import appLogo from "./assets/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import PlayList from "./screens/PlayList";
import AppNavigator from "./Router";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import history from "./History";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppNavigator />
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

export default App;
