import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import moment from "moment";
import { postGrantPremiumManyRequest } from "../redux/actions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  itemWrapper: {
    marginBottom: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / -1%), 0px 1px 0px 0px rgb(0 0 0 / 1%)",
    background: "#fff",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  createIcon: {
    cursor: "pointer",
  },
  iconsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "15%",
    paddingRight: 20,
  },
  textContentWrapper: {
    paddingRight: 20,
  },
  title: {
    fontSize: "1.2rem",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  description: {
    fontSize: ".95rem",
    marginBottom: 5,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
}));

// const initialState = {
//   premiumValidTillId: moment(new Date()).format("YYYY-MM-DD"),
// };

export default function CarouselItem({
  data,
  handleEdit,
  handleDelete,
  handleChangeVisibility,
  showEdit = false,
}) {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.config.isLoading);

  return (
    <div className={classes.itemWrapper}>
      <div className={classes.wrapper}>
        <div
          style={{
            display: "flex",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "250px",
              maxHeight: "200px",
              overflow: "hidden",
            }}
          >
            {data.videoId ? (
              <video width="250" controls>
                <source src={data.videoLink} type="video/mp4" />
              </video>
            ) : data.video?.videoLink ? (
              <video width="250" controls>
                <source src={data.video?.videoLink} type="video/mp4" />
              </video>
            ) : data.image ? (
              <img className={classes.image} src={data.image} alt="img" />
            ) : null}
          </div>
          <div
            style={{
              padding: 15,
              display: "flex",
              flexDirection: "column",
              justifyContent: `${data.image ? "center" : "flex-start"}`,
              alignItems: "center",
              maxWidth: "50%",
              width: "100%",
              minWidth: "400px",
            }}
          >
            {data.image && (
              <span
                style={{
                  display: "inline-block",
                  maxWidth: "400px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {data.caption}
              </span>
            )}
            {(data.videoId || data.video?.videoLink) && (
              <>
                <div
                  style={{
                    display: "inline-block",
                    maxWidth: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontSize: ".9rem",
                    fontWeight: "bold",
                  }}
                >
                  {data.title || data.video.title}
                </div>
                <div
                  style={{
                    display: "inline-block",
                    maxWidth: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontSize: ".8rem",
                    fontStyle: "italic",
                    padding: "5px 0",
                  }}
                >
                  {data.subTitle || data.video.subTitle}
                </div>
                <div
                  style={{
                    display: "-webkit-box",
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal", // Required to work with multi-line truncation
                    WebkitLineClamp: 2, // Limits to 2 lines
                    WebkitBoxOrient: "vertical", // Required for line clamp
                    width: "100%",
                    fontSize: ".7rem",
                  }}
                >
                  {data.description || data.video.description}
                </div>
              </>
            )}
          </div>
          <div
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {moment(data.video?.createdAt || data.createdAt).format(
              "DD/MM/YYYY, kk:mm:ss"
            )}
          </div>
        </div>
        <div className={classes.iconsWrapper}>
          {isLoading ? (
            <Loader variant="small" />
          ) : (
            <div>
              {/* {data.appearInCarousel ? (
                <VisibilityIcon
                  onClick={() => handleChangeVisibility(data)}
                  className={classes.deleteIcon}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => handleChangeVisibility(data)}
                  className={classes.deleteIcon}
                />
              )} */}
              {showEdit && (
                <CreateIcon
                  onClick={() => handleEdit(data)}
                  className={classes.createIcon}
                />
              )}

              <DeleteIcon
                onClick={() => handleDelete(data._id)}
                className={classes.deleteIcon}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
