import * as actions from "../actions";
import fireAjax from "../../services";
import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

//to get the carousel's data
export function* createTrainerRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `trainer`,
      "",
      action.payload
    );
    const trainers = yield call(fireAjax, "GET", `trainer`, "");
    yield put(actions.getTrainersSuccess(trainers.data.data));
    yield put(actions.createTrainerSuccess(response.data.data));
    yield put(actions.videoModalSuccess(false));
  } catch (e) {
    yield put(actions.createTrainerError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
  }
}

export function* deleteTrainerRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `trainer/${action.payload.trainerId}`,
      ""
    );
    const trainers = yield call(fireAjax, "GET", `trainer`, "");
    yield put(actions.getTrainersSuccess(trainers.data.data));
    yield put(actions.deleteTrainerSuccess(response.data.data));
  } catch (e) {
    yield put(actions.updateTrainerError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
    yield put(actions.videoModalSuccess(false));
  }
}

export function* updateTrainerRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `trainer/${action.payload.trainerId}`,
      "",
      action.payload.data
    );
    const trainers = yield call(fireAjax, "GET", `trainer`, "");
    yield put(actions.getTrainersSuccess(trainers.data.data));
    yield put(actions.updateTrainerSuccess(response.data.data));
    yield put(actions.videoModalSuccess(false));
  } catch (e) {
    yield put(actions.updateTrainerError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
  }
}

//to get the carousel's data
export function* createCoursesrRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `courses`,
      "",
      action.payload
    );
    const courses = yield call(fireAjax, "GET", `courses/temp/test`, "");
    yield put(actions.getCourseListSuccess(courses.data.data));
    yield put(actions.createCourseSuccess(response.data.data));
  } catch (e) {
    yield put(actions.createTrainerError({ message: e.message }));
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderSuccess(false));
  }
}

//to get the carousel's data
export function* updateCoursesrRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `courses/${action.payload.courseId}`,
      "",
      action.payload.data
    );
    const courses = yield call(fireAjax, "GET", `courses/temp/test`, "");
    yield put(actions.getCourseListSuccess(courses.data.data));
    yield put(actions.updateCourseSuccess(response.data.data));
    yield put(actions.videoModalSuccess(false));
  } catch (e) {
    yield put(actions.createTrainerError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* deleteCoursesRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `courses/${action.payload.courseId}`,
      "",
      action.payload.data
    );
    const courses = yield call(fireAjax, "GET", `courses/temp/test`, "");
    yield put(actions.getCourseListSuccess(courses.data.data));
    yield put(actions.deleteTrainerSuccess(response.data.data));
  } catch (e) {
  } finally {
    yield put(actions.genericLoaderSuccess(false));
  }
}

export function* deleteVideoRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `videos/${action.payload.videoId}`,
      "",
      action.payload.data
    );
    const vidoes = yield call(fireAjax, "GET", `videos`, "");
    if (Array.isArray(vidoes.data) && vidoes.data.length) {
      yield put(actions.getVideoSuccess(vidoes.data));
    } else {
      yield put(actions.getVideoError({ message: "No video is available" }));
    }
    yield put(actions.deleteVideoSuccess(response.data.data));
  } catch (e) {
    yield put(actions.deleteVideoError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

//to get the carousel's data
export function* getCourseListRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `courses/temp/test`, "");
    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(actions.getCourseListSuccess(response.data.data));
    } else {
      throw new Error("No Programme is available");
    }
  } catch (e) {
    yield put(actions.getCourseListError({ message: e.message }));
  }
}

//to create the hero carousel's data
export function* createCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/sliderData`,
      "",
      action.payload.formdata
    );
    const sliderData = yield call(
      fireAjax,
      "GET",
      `content/listSliderData?webapp=true`,
      ""
    );
    if (Array.isArray(sliderData.data.data) && sliderData.data.data.length) {
      yield put(actions.getCarouselDataSuccess(sliderData.data.data.reverse()));
    } else {
      throw new Error("No data is available");
    }
    // if(Array.isArray(response.data.data) && response.data.data.length ){
    yield put(actions.createCarouselDataSuccess(response.data.data));
    // }else{
    //   throw new Error('No Programme is available')
    // }
  } catch (e) {
    yield put(actions.createCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

//to create the hero carousel's data
export function* deleteCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `content/sliderData/${action.payload._id}`,
      "",
      ""
    );
    const sliderData = yield call(
      fireAjax,
      "GET",
      `content/listSliderData?webapp=true`,
      ""
    );
    if (Array.isArray(sliderData.data.data) && sliderData.data.data.length) {
      yield put(actions.getCarouselDataSuccess(sliderData.data.data.reverse()));
    } else {
      throw new Error("No data is available");
    }
    yield put(actions.deleteCarouselDataSuccess(response.data.data));
  } catch (e) {
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

//to update the hero carousel's data
export function* updateCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `content/sliderData/${action.payload._id}`,
      "",
      action.payload.formdata
    );
    const sliderData = yield call(
      fireAjax,
      "GET",
      `content/listSliderData?webapp=true`,
      ""
    );
    if (Array.isArray(sliderData.data.data) && sliderData.data.data.length) {
      yield put(actions.getCarouselDataSuccess(sliderData.data.data.reverse()));
    } else {
      throw new Error("No data is available");
    }
    yield put(actions.updateCarouselDataSuccess(response.data.data));
  } catch (e) {
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

//to get the hero carousel's data
export function* getCarouselDataRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "GET",
      `content/listSliderData?webapp=true`,
      ""
    );
    // if(Array.isArray(response.data.data) && response.data.data.length ){
    yield put(actions.getCarouselDataSuccess(response.data.data.reverse()));
    // }else{
    //   throw new Error('No Programme is available')
    // }
  } catch (e) {
    yield put(actions.getCarouselDataError({ message: e.message }));
  }
}

//to get the carousel's data
export function* getTrainersRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `trainer`, "");
    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(actions.getTrainersSuccess(response.data.data));
    } else {
      throw new Error("No Trainer is available");
    }
  } catch (e) {
    yield put(actions.getTrainersError({ message: e.message }));
  }
}

//to upload video
export function* uploadVideoRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    // upload video
    const response = yield call(
      fireAjax,
      "POST",
      `videos`,
      "",
      action.payload.formData
    );
    // const videos = yield call(fireAjax, "GET", `videos`, "");
    // yield put(actions.getVideoSuccess(videos.data));

    // upload video type
    if (response.data.data) {
      if (action.payload.uploadType === "keyConcept") {
        const newData = { ...response.data.data };
        yield put(actions.postKeyConceptDataRequest({ videoId: newData._id }));
      } else if (action.payload.uploadType === "topCarousel") {
        const newData = { ...response.data.data };
        yield put(actions.postTopCarouselDataRequest({ videoId: newData._id }));
      } else {
        const newData = { ...response.data.data };
        yield put(
          actions.addItemToVideobankRequest({
            videoId: newData._id,
            bankType: action.payload.uploadType,
          })
        );
      }

      yield put(actions.uploadVideoSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.uploadVideoError({ message: e.message }));
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderSuccess(false));
  }
}

//to update video info
export function* updateVideoRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `videos/${action.payload._id}`,
      "",
      action.payload.payload
    );
    const videos = yield call(fireAjax, "GET", `videoBank`, "");
    if (Array.isArray(videos.data.data) && videos.data.data.length) {
      yield put(actions.getVideoBankSuccess(videos.data.data));
    } else {
      yield put(actions.getVideoBankError({ message: "Video bank is empty" }));
    }
    // yield put(actions.getVideoSuccess(videos.data));
    yield put(actions.updateVideoSuccess(response.data.data));
  } catch (e) {
    yield put(actions.updateVideoError({ message: e.message }));
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderSuccess(false));
  }
}

export function* getViodesRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `videos`, "");
    if (Array.isArray(response.data) && response.data.length) {
      yield put(actions.getVideoSuccess(response.data));
    } else {
      throw new Error("No video is available");
    }
  } catch (e) {
    yield put(actions.getVideoError({ message: e.message }));
  }
}

export function* createQuestionRequest(action) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/createQuestion`,
      headers,
      action.payload
    );

    yield put(
      actions.createQuestionSuccess({ data: response.data, ...action.payload })
    );
  } catch (e) {
    yield put(actions.createQuestionError({ message: e.message }));
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderRequest(false));
  }
}

//to get the video bank
export function* getVideoBankRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `videoBank`, "");
    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(actions.getVideoBankSuccess(response.data.data));
    } else {
      throw new Error("Video bank is empty");
    }
  } catch (e) {
    yield put(actions.getVideoBankError({ message: e.message }));
  }
}

export function* deleteVideoBankRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `videoBank/${action.payload._id}`,
      "",
      ""
    );
    const videos = yield call(fireAjax, "GET", `videoBank`, "");
    if (Array.isArray(videos.data.data) && videos.data.data.length) {
      yield put(actions.getVideoBankSuccess(videos.data.data));
    } else {
      yield put(actions.getVideoBankError({ message: "Video bank is empty" }));
    }
    yield put(actions.deleteVideobankSuccess(response.data.data));
  } catch (e) {
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* addItemToVideoBankRequest(action) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `videoBank`,
      headers,
      action.payload
    );
    const videos = yield call(fireAjax, "GET", `videoBank`, "");
    if (Array.isArray(videos.data.data) && videos.data.data.length) {
      yield put(actions.getVideoBankSuccess(videos.data.data));
    } else {
      yield put(actions.getVideoBankError({ message: "Video bank is empty" }));
    }
    yield put(actions.addItemToVideobankSuccess(response.data.data));
  } catch (e) {
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderRequest(false));
  }
}

//to get the user list bank
export function* getUserListRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "GET",
      `users?search=${action?.payload?.data?.filter}&createdAtFrom=${action?.payload?.data?.createdAtFrom}&createdAtTo=${action?.payload?.data?.createdAtTo}&sortBy=${action?.payload?.data?.orderBy}&sortOrder=${action?.payload?.data?.sortOrder}`,
      ""
    );

    if (Array.isArray(response.data.data) && response.data.data.length) {
      const data = response.data.data.map((value) => {
        if (value.subscription === "premium") {
          return {
            ...value,
            selectIndex: 1,
          };
        } else {
          return {
            ...value,
            selectIndex: 0,
          };
        }
      });
      yield put(actions.getUserListSuccess(data));
    } else {
      throw new Error("User List is empty");
    }
  } catch (e) {
    yield put(actions.getUserListError({ message: e.message }));
  }
}

//to get the question category
export function* getQuestionCategoryRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "GET",
      `content/getQuestionCategory`,
      ""
    );

    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(
        actions.getQuestionCategorySuccess(
          response.data.data.map((a) => ({ ...a }))
        )
      );
    } else {
      throw new Error("No Question Category has been added yet.");
    }
  } catch (e) {
    yield put(actions.getQuestionCategoryError({ message: e.message }));
  }
}

//to create the question category
export function* createQuestionCategoryRequest(action) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/createQuestionCategory`,
      headers,
      action.payload
    );

    yield put(actions.createQuestionCategorySuccess(response.data.data));
  } catch (e) {
    yield put(actions.createQuestionCategoryError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

//to delete the question category
export function* deleteQuestionCategoryRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `content/deleteQuestionCategory/${action.payload.categoryId}`,
      ""
    );

    yield put(actions.deleteQuestionCategorySuccess(action.payload));
  } catch (e) {}
}

//to update the question category
export function* updateQuestionCategoryRequest(action) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `content/updateQuestionCategory/${action.payload.categoryId}`,
      headers,
      action.payload.data
    );

    yield put(actions.updateQuestionCategorySuccess(action.payload));
  } catch (e) {
    // yield put(actions.createQuestionCategoryError({ message: e.message }));
  } finally {
    yield put(actions.videoModalSuccess(false));
    yield put(actions.genericLoaderRequest(false));
  }
}

//to select the question category
export function* selectQuestionCategoryRequest(action) {
  try {
    yield put(
      actions.selectQuestionCategorySuccess({ _id: action.payload._id })
    );
  } catch (e) {}
}

//to update the question category
export function* updateQuestionyRequest(action) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    yield call(
      fireAjax,
      "PUT",
      `content/updateQuestion/${action.payload.questionId}`,
      headers,
      action.payload.data
    );

    const response = yield call(
      fireAjax,
      "GET",
      `content/getQuestionCategory`,
      ""
    );

    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(actions.getQuestionCategorySuccess(response.data.data));
    }

    yield put(actions.updateQuestionSuccess(action.payload));
  } catch (e) {
    // yield put(actions.createQuestionCategoryError({ message: e.message }));
  } finally {
    yield put(actions.updateQuestionError(false));
  }
}

//to create the hero carousel's data
export function* setPaidContentRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    yield call(
      fireAjax,
      "PUT",
      `content/setFreeContent`,
      "",
      action.payload.data
    );
    const response = yield call(
      fireAjax,
      "GET",
      `content/getQuestionCategory`,
      ""
    );

    if (Array.isArray(response.data.data) && response.data.data.length) {
      yield put(actions.getQuestionCategorySuccess(response.data.data));
    } else {
      throw new Error("No Question Category has been added yet.");
    }
  } catch (e) {
    yield put(actions.createCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

//to update user to premium
export function* upgradeUserPremiumRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      "users/update",
      "",
      action.payload
    );
    if (response) {
      yield put(actions.upgradeUserPremiumSuccess(response.data));
    } else {
      throw new Error("Users not updated to premium");
    }
  } catch (e) {
    yield put(actions.upgradeUserPremiumError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
  }
}

export function* upgradeUserDataRequest(action) {
  const { userList, premiumEmail } = action.payload;
  yield put(actions.genericLoaderRequest(true));
  try {
    const userData = userList.map((user) => {
      if (premiumEmail.some((emailObj) => emailObj.email === user.email)) {
        return {
          ...user,
          subscription: "premium",
          selectIndex: 1,
        };
      }
      return {
        ...user,
        selectIndex: user.subscription === "premium" ? 1 : 0,
      };
    });
    yield put(actions.upgradeUserDataSuccess(userData));
    yield put(actions.getUserListSuccess(userData));
  } catch (error) {
    yield put(actions.upgradeUserDataError({ message: error.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
  }
}

//to get the coupon
export function* createCouponRequest(action) {
  console.log(action);
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `coupon/create`,
      "",
      action.payload
    );

    yield put(actions.createCouponSuccess(response.data.data));
  } catch (e) {
    yield put(actions.createCouponError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderSuccess(false));
    yield call(getCouponListRequest);
  }
}

export function* getCouponListRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `coupon/all`, "");

    yield put(actions.getCouponListSuccess(response.data.data));
  } catch (e) {
    yield put(actions.getCouponListError({ message: e.message }));
  }
}

export function* postGrantPremiumRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `coupon/grant-premium/${action.payload._id}`,
      "",
      action.payload.data
    );

    yield put(actions.postGrantPremiumSuccess(response.data.data));
  } catch (e) {
    yield put(actions.postGrantPremiumError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield call(getUserListRequest, {
      payload: {
        data: action.payload.search_data,
      },
    });
  }
}

export function* postGrantPremiumManyRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `coupon/grant-premium-many`,
      "",
      action.payload.data
    );

    yield put(actions.postGrantPremiumManySuccess(response.data.data));
    toast.success("Grant Premium Successfully", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } catch (e) {
    yield put(actions.postGrantPremiumManyError({ message: e.message }));
    toast.error(e.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield call(getUserListRequest, {
      payload: {
        data: action.payload.search_data,
      },
    });
  }
}

//Actionable Insight
export function* getActionableInsightDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));

  try {
    const response = yield call(
      fireAjax,
      "GET",
      `content/actionable-insight`,
      ""
    );
    yield put(actions.getActionableInsightDataSuccess(response.data.data));
  } catch (e) {
    yield put(actions.getActionableInsightDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* postActionableInsightDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/actionable-insight`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getActionableInsightDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.postActionableInsightDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postActionableInsightDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

export function* patchActionableInsightDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/actionable-insight/${action.payload._id}`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getActionableInsightDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchActionableInsightDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchActionableInsightDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* deleteActionableInsightDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `content/actionable-insight/${action.payload._id}`,
      ""
    );

    if (response) {
      yield put(actions.getActionableInsightDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.deleteActionableInsightDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postActionableInsightDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* patchOrderActionableInsightDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/actionable-insight-order`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getActionableInsightDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchOrderActionableInsightDataSuccess(response.data));
  } catch (e) {
    yield put(
      actions.patchOrderActionableInsightDataError({ message: e.message })
    );
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

//Key Concept
export function* getKeyConceptDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));

  try {
    const response = yield call(fireAjax, "GET", `content/key-concept`, "");
    yield put(actions.getKeyConceptDataSuccess(response.data.data));
  } catch (e) {
    yield put(actions.getKeyConceptDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* postKeyConceptDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/key-concept`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getKeyConceptDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.postKeyConceptDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postKeyConceptDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

export function* patchKeyConceptDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/key-concept/${action.payload._id}`,
      "",
      action.payload.payload
    );

    if (response) {
      yield put(actions.getKeyConceptDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchKeyConceptDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchKeyConceptDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));

    if (action.payload.typeUpdate === "update") {
      yield put(actions.videoModalSuccess(false));
    }
  }
}

export function* deleteKeyConceptDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `content/key-concept/${action.payload._id}`,
      ""
    );

    if (response) {
      yield put(actions.getKeyConceptDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.deleteKeyConceptDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postKeyConceptDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* patchOrderKeyConceptDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/key-concept-order`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getKeyConceptDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchOrderKeyConceptDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchOrderKeyConceptDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

//Top Carousel
export function* getTopCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));

  try {
    const response = yield call(fireAjax, "GET", `content/main-carousel`, "");
    yield put(actions.getTopCarouselDataSuccess(response.data.data));
  } catch (e) {
    yield put(actions.getTopCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* postTopCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `content/main-carousel`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getTopCarouselDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.postTopCarouselDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postTopCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
    yield put(actions.videoModalSuccess(false));
  }
}

export function* patchTopCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/main-carousel/${action.payload._id}`,
      "",
      action.payload.payload
    );

    if (response) {
      yield put(actions.getTopCarouselDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchTopCarouselDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchTopCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));

    if (action.payload.typeUpdate === "update") {
      yield put(actions.videoModalSuccess(false));
    }
  }
}

export function* deleteTopCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `content/main-carousel/${action.payload._id}`,
      ""
    );

    if (response) {
      yield put(actions.getTopCarouselDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.deleteTopCarouselDataSuccess(response.data));
  } catch (e) {
    yield put(actions.postTopCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

export function* patchOrderTopCarouselDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `content/main-carousel-order`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getTopCarouselDataRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchOrderTopCarouselDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchOrderTopCarouselDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}

//Video bank
export function* patchVideoBankDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `videoBank/${action.payload._id}`,
      "",
      action.payload.payload
    );

    if (response) {
      yield put(actions.getVideoBankRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchVideoBankDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchVideoBankDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));

    if (action.payload.typeUpdate === "update") {
      yield put(actions.videoModalSuccess(false));
    }
  }
}

export function* patchOrderVideoBankDataRequest(action) {
  yield put(actions.genericLoaderRequest(true));
  try {
    const response = yield call(
      fireAjax,
      "PATCH",
      `videoBank/order-by-type`,
      "",
      action.payload
    );

    if (response) {
      yield put(actions.getVideoBankRequest());
    } else {
      throw new Error("No data is available");
    }

    yield put(actions.patchOrderVideoBankDataSuccess(response.data));
  } catch (e) {
    yield put(actions.patchOrderVideoBankDataError({ message: e.message }));
  } finally {
    yield put(actions.genericLoaderRequest(false));
  }
}
