import React, { useState, useEffect, useImperativeHandle } from "react";
import CommonModal from "./CommonModal";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  videoModalRequest,
  postTopCarouselDataRequest,
  uploadVideoRequest,
  patchTopCarouselDataRequest,
  setModalDataRequest,
} from "../redux/actions";
import GenericTextArea from "./GenericTextArea";
import Loader from "./Loader";
import validator from "../utils/validation";
import MultipleSelect from "./Select";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ErrorMessage from "./ErrorMessage";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import ForwardIcon from "@material-ui/icons/Forward";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(0,0,0, 0.2)",
  },
  closeIcon: {
    cursor: "pointer",
  },
  content: {
    flex: 1,
    padding: 30,
    overflowY: "scroll",
  },
  footer: {
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0,0,0, 0.2)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    outline: "none",
    border: "none",
    background: "#065fd4",
    color: "#fff",
    width: 70,
    height: 40,
    cursor: "pointer",
  },
  inputsWrapper: {
    width: "100%",
  },
  divider: {
    // marginBottom: 10
  },
  selectWrapper: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    marginLeft: -theme.spacing(1),
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10,
    left: 0,
    cursor: "pointer",
    background: "#fff",
    objectFit: "contain",
  },
  addThumbnail: {
    border: "1px dotted rgba(0,0,0,0.2)",
    position: "relative",
    borderRadius: 5,
    display: "flex",
    width: "60%",
    // padding: "2rem",
    height: "200px",
    maxHeight: "500px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // margin: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
    },
  },
  input: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 100,
    left: 0,
    opacity: 0,
    cursor: "pointer",
  },
  imageUploadWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(8),
    flexDirection: "column",
  },
  checkboxWrapperCarousel: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  uploadWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: 30,
    paddingBottom: 30,
    // overflowY: "scroll",
  },
  uploadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    height: "50%",
  },
  uploadIconUnderline: {
    borderBottom: "5px solid rgba(0,0,0,0.5)",
    height: "1px",
    width: "41px",
  },
  uploadIconUnderlineSmall: {
    borderBottom: "3px solid #065fd4",
    height: "1px",
    width: "20px",
  },
  uploadIcon: {
    transform: "rotate(-90deg)",
    fontSize: "3.5rem",
    color: "rgba(0,0,0,0.5)",
  },
  uploadIconSmall: {
    transform: "rotate(-90deg)",
    fontSize: "1.5rem",
    color: "#065fd4",
  },
  uploadIconContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4f4f4",
    height: 136,
    width: 136,
    borderRadius: 136 / 2,
    marginBottom: 20,
    cursor: "pointer",
  },
  uploadIconContainerSmall: {
    position: " relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  dragTag: {},
  //   footer: {
  //     padding: theme.spacing(2),
  //     borderTop: "1px solid rgba(0,0,0, 0.2)",
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "space-between",
  //     alignItems: "center",
  //   },
  //   button: {
  //     outline: "none",
  //     border: "none",
  //     background: "#065fd4",
  //     color: "#fff",
  //     width: 70,
  //     height: 40,
  //     cursor: "pointer",
  //   },
  footerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#065fd4",
  },
  //   inputsWrapper: {
  //     width: "100%",
  //   },
  details: {
    fontWeight: "500",
    fontSize: "1.2rem",
    marginBottom: 10,
  },
  sectionTitle: {
    fontWeight: "500",
  },
  thumbnailDescription: {
    fontWeight: "normal",
    fontSize: "13px",
    color: "#808080c9",
  },
  descriptionWrapper: {
    margin: "25px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    width: "50%",
  },
  titleAreaWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  //   addThumbnail: {
  //     border: "1px dotted rgba(0,0,0,0.2)",
  //     position: "relative",
  //     borderRadius: 5,
  //     display: "flex",
  //     width: "35%",
  //     padding: "2rem",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     cursor: "pointer",
  //     margin: "20px 0px",
  //     [theme.breakpoints.down("sm")]: {
  //       width: "55%",
  //     },
  //   },
  uploadThumbnailText: {
    fontSize: "13px",
    color: "grey",
  },
  //   input: {
  //     width: "100%",
  //     height: "100%",
  //     position: "absolute",
  //     zIndex: 100,
  //     left: 0,
  //     opacity: 0,
  //     cursor: "pointer",
  //   },
  //   thumbnail: {
  //     width: "100%",
  //     height: "100%",
  //     position: "absolute",
  //     zIndex: 10,
  //     left: 0,
  //     cursor: "pointer",
  //     background: "#fff",
  //   },
  //   checkboxWrapperCarousel: {
  //     display: "flex",
  //     alignItems: "center",
  //     marginTop: 10,
  //   },
  checkboxWrapperVideoBank: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  videoBankTypeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 20,
  },
}));

const initialState = {
  errors: {},
  type: "image",
  title: "",
  subtitle: "",
  description: "",
  image: "", // local: file of uploaded image, api: image url
  url: "", // local: uploaded image url, api: image url
  // videoId: "",
  file: "",
  appearInCarousel: true,
  caption: "",
  thumbnail: "",
};

const selectionType = [
  { name: "Video", type: "video" },
  { name: "Image", type: "image" },
];

const TopCarouselUploader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const createQuestion = useSelector((state) => state.course.createQuestion);
  const isLoading = useSelector((state) => state.config.isLoading);
  // const videos = useSelector((state) => state.course.videos);
  const [state, setState] = useState(initialState);
  const modalData = useSelector((state) => state.config.modalData);
  const isToUpdate = Object.keys(modalData).length > 0;

  const handleClose = () => {
    dispatch(videoModalRequest(false));
    handleResetHandle();
  };
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);
  const handleResetHandle = () => {
    setState(initialState);
    dispatch(setModalDataRequest({}));
  };

  const handleUpload = () => {
    const errors = validator(state);
    // console.log(state);
    // console.log(errors);

    if (isToUpdate /* || state.isPdf */) {
      delete errors.thumbnail;
    }

    if (state.type === "image") {
      delete errors.title;
      delete errors.description;
      delete errors.subtitle;
      delete errors.thumbnail;
    }

    setState({ ...state, errors });
    if (Object.keys(errors).length) {
      //do something
      toast.error(errors[Object.keys(errors)[0]], {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    let formData = new FormData();
    formData.append("appearInCarousel", state.appearInCarousel);
    formData.append("type", state.type);

    if (state.type === "image") {
      formData.append("image", state.image);
      formData.append("caption", state.caption);
    } else if (state.type === "video") {
      formData.append("video", state.file);
      formData.append("title", state.title);
      formData.append("subTitle", state.subtitle);
      formData.append("description", state.description || "");
      formData.append("image", state.thumbnail);
    }

    if (isToUpdate) {
      if (state.type === "image") {
        formData.delete("image");
      } else if (state.type === "video") {
        formData.delete("video");
        formData.delete("image");
      }
      formData.delete("appearInCarousel");
      formData.delete("type");

      dispatch(
        patchTopCarouselDataRequest({
          payload: formData,
          _id: modalData._id,
          typeUpdate: "update",
        })
      );
    } else {
      if (state.type === "video") {
        dispatch(
          uploadVideoRequest({
            formData,
            uploadType: "topCarousel",
          })
        );
      } else if (state.type === "image") {
        dispatch(postTopCarouselDataRequest(formData));
      }
    }
  };

  useEffect(() => {
    if (isToUpdate) {
      if (modalData.type === "video") {
        setState({
          ...state,
          uploading: true,
          type: modalData.type,
          description: modalData.description,
          title: modalData.title,
          subtitle: modalData.subTitle,
          url: modalData.image,
        });
      } else if (modalData.type === "image") {
        setState({
          ...state,
          type: modalData.type,
          url: modalData.image,
          uploading: true,
          caption: modalData.caption || "",
        });
      }
    }
  }, [isToUpdate]);

  const handleChange = (value, name) => {
    setState({ ...state, [name]: value });
  };

  const handleReset = () => {
    setState(initialState);
  };

  function onChange(event, name) {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      setState({
        ...state,
        [name]: file,
        uploading: true,
        // isPdf,
        url:
          state.type === "image"
            ? event.target.result
            : name === "thumbnail"
            ? event.target.result
            : "",
      });
    };

    file && reader.readAsDataURL(file);
  }

  return (
    <CommonModal onClose={handleReset}>
      <div className={classes.modalHeader}>
        <span>Upload Media</span>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </div>
      <div className={classes.content}>
        <div className={classes.selectWrapper}>
          <MultipleSelect
            isToUpdate={isToUpdate}
            selectedKeys={state.type}
            multiple={false}
            nameKey="name"
            name="type"
            onSelect={handleChange}
            list={selectionType}
            returnKey="type"
            defaultSelect={true}
          />
        </div>
        <div className={classes.divider} />
        {state.type === "image" && (
          <>
            <div className={classes.imageUploadWrapper}>
              <div className={classes.addThumbnail}>
                {state.url !== "" && (
                  <img
                    className={classes.thumbnail}
                    src={state.url}
                    alt="Uploaded Img"
                  />
                )}
                <input
                  onChange={(e) => onChange(e, "image")}
                  accept="image/*"
                  className={classes.input}
                  type="file"
                  capture
                  disabled={isToUpdate}
                />
                <AddPhotoAlternateIcon />
                <div className={classes.uploadThumbnailText}>Upload Image</div>
              </div>
              <ErrorMessage error={state?.errors?.file} />
              <GenericTextArea
                value={state.caption}
                name={"caption"}
                handleChange={handleChange}
                style={{ width: "60%", marginTop: 10 }}
                isBigger={true}
                placeholder={"Image caption..."}
              />
            </div>
          </>
        )}
        {state.type === "video" && (
          <div
            className={
              state.uploading ? classes.uploadWrapper : classes.uploadingWrapper
            }
          >
            {state.uploading && (
              <div className={classes.inputsWrapper}>
                <div className={classes.details}>Details</div>
                <div className={classes.titleAreaWrapper}>
                  <GenericTextArea
                    value={state.title}
                    handleChange={handleChange}
                    name="title"
                    title="Title (required)"
                    placeholder="Add a title that describe your video"
                    error={state?.errors?.title}
                  />
                </div>
                <div className={classes.descriptionWrapper}>
                  <GenericTextArea
                    value={state.subtitle}
                    handleChange={handleChange}
                    name="subtitle"
                    title="Subtitle (required)"
                    placeholder="Add a subtitle "
                    error={state?.errors?.subtitle}
                  />
                </div>
                <div className={classes.descriptionWrapper}>
                  <GenericTextArea
                    value={state.description}
                    handleChange={handleChange}
                    name="description"
                    isBigger={true}
                    title="Description"
                    placeholder="Tell viewers about your video"
                    error={state?.errors?.description}
                  />
                </div>
                {
                  /* !state.isPdf && */
                  <>
                    <div className={classes.sectionTitle}>Thumbnail</div>
                    <span className={classes.thumbnailDescription}>
                      Select or upload a picture that shows what's in your
                      video. A good thumbnail stands out and draws viewers'
                      attention.
                    </span>
                    <div className={classes.addThumbnail}>
                      {state.url !== "" && (
                        <img className={classes.thumbnail} src={state.url} />
                      )}
                      <input
                        onChange={(e) => onChange(e, "thumbnail")}
                        accept="image/*"
                        className={classes.input}
                        type="file"
                        capture
                        disabled={isToUpdate}
                      />
                      <AddPhotoAlternateIcon />
                      <div className={classes.uploadThumbnailText}>
                        Upload Thumbnail
                      </div>
                    </div>
                  </>
                }
                <ErrorMessage error={state?.errors?.thumbnail} />
              </div>
            )}
            {!state.uploading && (
              <React.Fragment>
                <div className={classes.uploadIconContainer}>
                  <input
                    onChange={(e) => onChange(e, "file")}
                    accept="video/*,.pdf"
                    className={classes.input}
                    type="file"
                    capture
                  />
                  <ForwardIcon className={classes.uploadIcon} />
                  <div className={classes.uploadIconUnderline} />
                </div>
                <div className={classes.dragTag}>
                  Drag and drop Video file to upload
                </div>
              </React.Fragment>
            )}
            {/* {state.uploading && (
              <div className={classes.footer}>
                <div className={classes.footerContent}>
                  {isLoading && (
                    <>
                      <div className={classes.uploadIconContainerSmall}>
                        <ForwardIcon className={classes.uploadIconSmall} />
                        <div className={classes.uploadIconUnderlineSmall} />
                      </div>
                      <span>Uploading...</span>
                    </>
                  )}
                </div>
                <button
                  disabled={isLoading}
                  onClick={handleUpload}
                  className={classes.button}
                >
                  {isLoading ? <Loader style={{ color: "#fff" }} /> : "UPLOAD"}
                </button>
              </div>
            )} */}
          </div>
        )}
      </div>
      {state.uploading && (
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            {isLoading && (
              <>
                <div className={classes.uploadIconContainerSmall}>
                  <ForwardIcon className={classes.uploadIconSmall} />
                  <div className={classes.uploadIconUnderlineSmall} />
                </div>
                <span>Uploading...</span>
              </>
            )}
          </div>
          <button
            disabled={isLoading}
            onClick={handleUpload}
            className={classes.button}
          >
            {isLoading ? <Loader style={{ color: "#fff" }} /> : "UPLOAD"}
          </button>
        </div>
      )}
      {/* {state.type === "image" && (
        <div className={classes.footer}>
          <button
            disabled={isLoading}
            onClick={handleUpload}
            className={classes.button}
          >
            {isLoading ? <Loader style={{ color: "#fff" }} /> : "UPLOAD"}
          </button>
        </div>
      )} */}
    </CommonModal>
  );
};
export default TopCarouselUploader;
