import React, { useEffect, useMemo, useState } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoBankRequest,
  deleteVideobankRequest,
  videoModalRequest,
  getVideoRequest,
  getKeyConceptDataRequest,
  postKeyConceptDataRequest,
  deleteKeyConceptDataRequest,
  patchKeyConceptDataRequest,
  patchOrderKeyConceptDataRequest,
  uploadVideoRequest,
  setModalDataRequest,
  patchVideoBankDataRequest,
  patchOrderVideoBankDataRequest,
  updateVideoRequest,
} from "../redux/actions";
import Loader from "../Components/Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import Error from "../Components/Error";
import PdfViewer from "../Components/PdfViewer";
import VideoBankSelectType from "../Components/VideoBankSelectType";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import CarouselItem from "../Components/CarouselItem";
import FileUploader from "../Components/VideoUploader";
import AddVideoBankModal from "../Components/AddQuestionBankModal";
import MultipleSelect from "../Components/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
  videoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {},
  },
  subtitle: {
    fontSize: "1.1rem",
  },
  videoDetailsWrapper: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {},
    width: "50%",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  contentWrapper: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    width: "95%",
  },
  pdfIcon: {
    width: 100,
  },
  pdfIconWrapper: {
    minWidth: 250,
  },
  keyConceptTypeWrapper: {},
  emptyContent: {
    textAlign: "center",
    marginTop: "20vh",
  },
  videoBankTypeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
}));

const list = [
  { name: "Monthly Q&A", videoBankType: "monthlyQa" },
  { name: "Master Class", videoBankType: "masterClass" },
  // { name: 'Video Bank', videoBankType: 'videoBank' },
  // { name: 'Key Concepts', videoBankType: 'keyConcept' },
  // { name: 'Actionable Insights', videoBankType: 'actionableInsights' },
];
export default function Key() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const videobank = useSelector((state) => state.course.videobank);
  // const [itemToDelete, setItemToDelete] = useState("");
  // const videos = useSelector((state) => state.course.videos);
  // const isLoading = useSelector((state) => state.config.isLoading);
  const [state, setState] = useState({
    videoBankType: "monthlyQa",
  });
  const [videoList, setVideoList] = useState([]);
  useMemo(() => {
    if (
      videobank.data &&
      Array.isArray(videobank.data) &&
      videobank.data.length > 0
    ) {
      const sortedData = videobank.data.sort(
        (a, b) => a.type_sort_index - b.type_sort_index
      );
      setVideoList(sortedData);
    }
  }, [videobank]);
  useEffect(() => {
    // if (!videos.isSuccess) {
    //   dispatch(getVideoRequest());
    // }
    dispatch(getVideoBankRequest());
  }, []);

  const handleUploadVideoBank = (formData) => {
    console.log("uploading video bank", formData);
    dispatch(uploadVideoRequest({ formData, uploadType: state.videoBankType }));
  };

  const handleUpdateVideoBank = (data) => {
    console.log("updating video bank", data);
    dispatch(updateVideoRequest(data));
  };

  const handleEditVideo = (item) => {
    dispatch(setModalDataRequest(item));
    dispatch(videoModalRequest(true));
  };

  const handleDeleteVideo = (id) => {
    dispatch(
      deleteVideobankRequest({ _id: id, videoBankType: state.videoBankType })
    );
    // console.log("Delete Video", id);
  };

  const handleChangeVideoVisibility = (data) => {
    const newData = { appearInCarousel: !data.appearInCarousel };

    dispatch(
      patchVideoBankDataRequest({
        payload: newData,
        _id: data._id,
        typeUpdate: "visibility",
      })
    );
  };
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list
    if (!destination) return;

    if (destination.index === source.index) return;

    // Reorder the items
    const reorderedItems = videoList.filter(
      (item) => item.type === state.videoBankType
    );
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    const newOrder = reorderedItems.map((item, index) => {
      return { _id: item._id, type_sort_index: index };
    });

    dispatch(
      patchOrderVideoBankDataRequest({
        type: state.videoBankType,
        order: newOrder,
      })
    );
    // setVideoList(reorderedItems);
  };
  const renderVideoBank = (data) => {
    const list = data.filter((a) => a.type === state.videoBankType);
    if (!list.length) {
      return (
        <div className={classes.emptyContent}>
          No content available for the selected category.
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`video-bank-dropable`}>
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list.map((item, index) => {
                  return (
                    <Draggable
                      key={item?._id}
                      draggableId={item?._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.videoWrapper}
                          style={{
                            ...provided.draggableProps.style,
                            marginTop: index === 0 ? 0 : "15px",
                          }}
                        >
                          <CarouselItem
                            key={index}
                            data={item}
                            handleEdit={handleEditVideo}
                            handleDelete={handleDeleteVideo}
                            // handleChangeVisibility={handleChangeVideoVisibility}
                            showEdit
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const handleOpenModal = () => {
    dispatch(videoModalRequest(true));
  };

  const handleChange = (value, name) => {
    setState({ ...state, [name]: value });
    console.log("value", value);
    console.log("name", name);
    console.log("state", state);
  };

  return (
    <div className={classes.root}>
      <FileUploader
        handleUpload={handleUploadVideoBank}
        handleEdit={handleUpdateVideoBank}
      />
      <div className={classes.titleWrapper}>
        <div className={classes.playlistTitle}>Video Bank</div>
      </div>
      {videobank.isSuccess && (
        <div className={classes.videoBankTypeWrapper}>
          <VideoBankSelectType onSelect={handleChange} name="videoBankType" />
        </div>
      )}
      {videobank.isLoading && <Loader variant="big" />}
      {videobank.isSuccess && renderVideoBank(videoList)}
      {videobank.isError && <Error message={videobank.message} />}
      <Fab
        size="small"
        onClick={handleOpenModal}
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
