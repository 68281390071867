import React, { useEffect, useMemo, useState } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoBankRequest,
  deleteVideobankRequest,
  videoModalRequest,
  getVideoRequest,
  getKeyConceptDataRequest,
  postKeyConceptDataRequest,
  deleteKeyConceptDataRequest,
  patchKeyConceptDataRequest,
  patchOrderKeyConceptDataRequest,
  uploadVideoRequest,
  setModalDataRequest,
} from "../redux/actions";
import Loader from "../Components/Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import Error from "../Components/Error";
import AddVideoBankModal from "../Components/AddQuestionBankModal";
import PdfViewer from "../Components/PdfViewer";
import VideoBankSelectType from "../Components/VideoBankSelectType";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import CarouselItem from "../Components/CarouselItem";
import FileUploader from "../Components/VideoUploader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
  videoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {},
  },
  subtitle: {
    fontSize: "1.1rem",
  },
  videoDetailsWrapper: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {},
    width: "50%",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  contentWrapper: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    width: "95%",
  },
  pdfIcon: {
    width: 100,
  },
  pdfIconWrapper: {
    minWidth: 250,
  },
  keyConceptTypeWrapper: {},
  emptyContent: {
    textAlign: "center",
    marginTop: "20vh",
  },
}));

export default function KeyConcept() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyConcept = useSelector((state) => state.course.keyConcept);
  const [itemToDelete, setItemToDelete] = useState("");
  const videos = useSelector((state) => state.course.videos);
  const isLoading = useSelector((state) => state.config.isLoading);
  // const [state, setState] = useState({ videoBankType: "monthlyQa" });
  const [videoList, setVideoList] = useState([]);
  useMemo(() => {
    if (
      keyConcept.data &&
      Array.isArray(keyConcept.data) &&
      keyConcept.data.length > 0
    ) {
      setVideoList(keyConcept.data);
    }
  }, [keyConcept]);
  // useEffect(() => {
  //   if (!videos.isSuccess) {
  //     dispatch(getVideoRequest());
  //   }
  //   dispatch(getVideoBankRequest());
  // }, []);
  useEffect(() => {
    dispatch(getKeyConceptDataRequest());
  }, []);

  const handleUploadKeyConcept = (formData) => {
    // console.log(data);
    dispatch(uploadVideoRequest({ formData, uploadType: "keyConcept" }));
  };

  const handleUpdateKeyConcept = (data) => {
    // console.log(data);
    dispatch(patchKeyConceptDataRequest(data));
  };

  const handleEditVideo = (item) => {
    dispatch(setModalDataRequest(item));
    dispatch(videoModalRequest(true));
  };

  const handleDeleteVideo = (id) => {
    dispatch(deleteKeyConceptDataRequest({ _id: id }));
    // console.log("Delete Video", id);
  };

  const handleChangeVideoVisibility = (data) => {
    const newData = { appearInCarousel: !data.appearInCarousel };

    dispatch(
      patchKeyConceptDataRequest({
        payload: newData,
        _id: data._id,
        typeUpdate: "visibility",
      })
    );
  };
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list
    if (!destination) return;

    if (destination.index === source.index) return;

    // Reorder the items
    const reorderedItems = [...videoList];
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    const newOrder = reorderedItems.map((item, index) => {
      return { _id: item._id, sort_index: index };
    });

    dispatch(patchOrderKeyConceptDataRequest({ order: newOrder }));
    setVideoList(reorderedItems);
  };
  const renderKeyConcept = () => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`key-concept-dropable`}>
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {videoList.map((item, index) => {
                  return (
                    <Draggable
                      key={item?._id}
                      draggableId={item?._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.videoWrapper}
                          style={{
                            ...provided.draggableProps.style,
                            marginTop: index === 0 ? 0 : "15px",
                          }}
                        >
                          <CarouselItem
                            key={index}
                            data={item}
                            handleEdit={handleEditVideo}
                            handleDelete={handleDeleteVideo}
                            handleChangeVisibility={handleChangeVideoVisibility}
                            showEdit
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const handleOpenModal = () => {
    dispatch(videoModalRequest(true));
  };

  return (
    <div className={classes.root}>
      {/* <AddVideoBankModal /> */}
      <FileUploader
        handleUpload={handleUploadKeyConcept}
        handleEdit={handleUpdateKeyConcept}
      />
      <div className={classes.titleWrapper}>
        <div className={classes.playlistTitle}>Key Concept</div>
      </div>
      {/* {keyConcept.isSuccess && (
        <div className={classes.videoBankTypeWrapper}>
          <VideoBankSelectType
            name="videoBankType"
            onSelect={(videoBankType) => setState({ ...state, videoBankType })}
          />
        </div>
      )} */}
      {keyConcept.isLoading && <Loader variant="big" />}
      {keyConcept.isSuccess && renderKeyConcept()}
      {keyConcept.isError && <Error message={keyConcept.message} />}
      <Fab
        size="small"
        onClick={handleOpenModal}
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
