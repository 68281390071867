import { createAction, handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";
const initialState = {
  createTrainer: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  allTrainers: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  courses: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createCourses: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  videos: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createVideos: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createQuestion: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  heroCarousel: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  videobank: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  addItemTovideobank: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  userlist: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  questionCategories: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  selectedQuestionCategory: {
    data: {},
  },

  updateQuestion: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  upgradeUserPremiun: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },

  upgradeUserData: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  coupons: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  newCoupon: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  grantPremium: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  actionableInsight: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createActionableInsight: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  updateActionableInsight: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  orderActionableInsight: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  keyConcept: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createKeyConcept: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  updateKeyConcept: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  orderKeyConcept: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  topCarousel: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  createTopCarousel: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  updateTopCarousel: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  orderTopCarousel: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  updateVideoBank: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
  orderVideoBank: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
};

const createTrainerRequest = (state, action) => {
  return update(state, {
    createTrainer: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const createTrainerSuccess = (state, action) => {
  return update(state, {
    createTrainer: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const createTrainerError = (state, action) => {
  return update(state, {
    createTrainer: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
//end

const getTrainersRequest = (state, action) => {
  return update(state, {
    allTrainers: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getTrainersSuccess = (state, action) => {
  return update(state, {
    allTrainers: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getTrainersError = (state, action) => {
  return update(state, {
    allTrainers: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};
//end

const getCoursesRequest = (state, action) => {
  return update(state, {
    courses: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getCoursesSuccess = (state, action) => {
  return update(state, {
    courses: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getCoursesError = (state, action) => {
  return update(state, {
    courses: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};
//end

const createCoursesRequest = (state, action) => {
  return update(state, {
    createCourses: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const createCoursesSuccess = (state, action) => {
  return update(state, {
    createCourses: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const createCoursesError = (state, action) => {
  return update(state, {
    createCourses: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
//end

const getVidoesRequest = (state, action) => {
  return update(state, {
    videos: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getVidoesSuccess = (state, action) => {
  return update(state, {
    videos: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getVidoesError = (state, action) => {
  return update(state, {
    videos: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

//end

const createVideoRequest = (state, action) => {
  return update(state, {
    createVideos: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const createVideoSuccess = (state, action) => {
  return update(state, {
    createVideos: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const createVideoError = (state, action) => {
  return update(state, {
    createVideos: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

//end

const createQuestionRequest = (state, action) => {
  return update(state, {
    createQuestion: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const createQuestionSuccess = (state, action) => {
  const { categoryId, parentCategoryId, data } = action.payload;
  let questionCategories = { ...state.questionCategories };
  const parentIndex = questionCategories.data.findIndex(
    (a) => a._id === parentCategoryId
  );
  const categoryIndex = questionCategories.data[
    parentIndex
  ].subCategories.findIndex((a) => a._id === categoryId);
  questionCategories.data[parentIndex].subCategories[
    categoryIndex
  ].questions.push(data);

  return update(state, {
    createQuestion: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
    questionCategories: { $set: questionCategories },
  });
};

const createQuestionError = (state, action) => {
  return update(state, {
    createQuestion: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

//end

const getcCarouselDataRequest = (state, action) => {
  return update(state, {
    heroCarousel: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getcCarouselDataSuccess = (state, action) => {
  return update(state, {
    heroCarousel: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getcCarouselDataError = (state, action) => {
  return update(state, {
    heroCarousel: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

//end

const getVideobankRequest = (state, action) => {
  return update(state, {
    videobank: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getVideobankSuccess = (state, action) => {
  const data = action.payload.filter((item) => item.video);
  return update(state, {
    videobank: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: data },
    },
  });
};

const getVideobankError = (state, action) => {
  return update(state, {
    videobank: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

//end

const addItemTovideobankRequest = (state, action) => {
  return update(state, {
    addItemTovideobank: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const addItemTovideobankSuccess = (state, action) => {
  return update(state, {
    addItemTovideobank: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const addItemTovideobankError = (state, action) => {
  return update(state, {
    addItemTovideobank: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

//end

const getUserListRequest = (state, action) => {
  return update(state, {
    userlist: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      // data: { $set:{} }
    },
  });
};

const getUserListSuccess = (state, action) => {
  return update(state, {
    userlist: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getUserListError = (state, action) => {
  return update(state, {
    userlist: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

//end

const getQuestionCategoryRequest = (state, action) => {
  return update(state, {
    questionCategories: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getQuestionCategorySuccess = (state, action) => {
  let selectedQuestionCategory = {};
  if (state.selectedQuestionCategory.data._id) {
    selectedQuestionCategory = action.payload.find(
      (a) => a._id === state.selectedQuestionCategory.data._id
    );
  } else {
    selectedQuestionCategory = action.payload[0];
  }

  return update(state, {
    questionCategories: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
    selectedQuestionCategory: {
      data: { $set: selectedQuestionCategory },
    },
  });
};

const getQuestionCategoryError = (state, action) => {
  return update(state, {
    questionCategories: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const createQuestionCategorySuccess = (state, action) => {
  const { parentCategoryId } = action.payload;
  const data = [...state.questionCategories.data];
  const parentIndex = state.questionCategories.data.findIndex(
    (a) => a._id === parentCategoryId
  );
  let payload = { ...action.payload };
  payload.questions = action.payload.questions || [];
  data[parentIndex].subCategories.push(payload);

  return update(state, {
    questionCategories: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: data },
    },
  });
};

const deleteQuestionCategorySuccess = (state, action) => {
  const { categoryId, parentCategoryId } = action.payload;
  let questionCategories = { ...state.questionCategories };
  const parentIndex = questionCategories.data.findIndex(
    (a) => a._id === parentCategoryId
  );
  questionCategories.data[parentIndex].subCategories = questionCategories.data[
    parentIndex
  ].subCategories.filter((a) => a._id !== categoryId);
  if (!questionCategories.data.length) {
    questionCategories.isSuccess = false;
    questionCategories.isError = true;
    questionCategories.message = "No Question Category has been added yet.";
  }
  return update(state, {
    questionCategories: { $set: questionCategories },
  });
};

const updateQuestionCategorySuccess = (state, action) => {
  const { categoryId, data } = action.payload;
  const { name, parentCategoryId } = data;
  let questionCategories = { ...state.questionCategories };
  const parentIndex = questionCategories.data.findIndex(
    (a) => a._id === parentCategoryId
  );
  const categoryIndex = questionCategories.data[
    parentIndex
  ].subCategories.findIndex((a) => a._id == categoryId);
  questionCategories.data[parentIndex].subCategories[categoryIndex].name = name;

  return update(state, {
    questionCategories: { $set: questionCategories },
  });
};

const selectQuestionCategorySuccess = (state, action) => {
  const data = state.questionCategories.data.find(
    (a) => a._id === action.payload._id
  );
  return update(state, {
    selectedQuestionCategory: {
      data: { $set: data },
    },
  });
};

//end

const updateQuestionRequest = (state, action) => {
  return update(state, {
    updateQuestion: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const updateQuestionSuccess = (state, action) => {
  return update(state, {
    updateQuestion: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const updateQuestionError = (state, action) => {
  return update(state, {
    updateQuestion: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const upgradeUserPremiumRequest = (state, action) => {
  return update(state, {
    upgradeUserPremiun: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
    },
  });
};

const upgradeUserPremiumSuccess = (state, action) => {
  return update(state, {
    upgradeUserPremiun: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const upgradeUserPremiumError = (state, action) => {
  return update(state, {
    upgradeUserPremiun: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

const upgradeUserDataRequest = (state, action) => {
  return update(state, {
    upgradeUserData: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
    },
  });
};

const upgradeUserDataSuccess = (state, action) => {
  return update(state, {
    upgradeUserData: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action },
    },
  });
};

const upgradeUserDataError = (state, action) => {
  return update(state, {
    upgradeUserData: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: action.payload.message },
    },
  });
};

const createCouponRequest = (state, action) => {
  return update(state, {
    newCoupon: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const createCouponSuccess = (state, action) => {
  return update(state, {
    newCoupon: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const createCouponError = (state, action) => {
  return update(state, {
    newCoupon: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const getCouponListRequest = (state, action) => {
  return update(state, {
    coupons: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const getCouponListSuccess = (state, action) => {
  return update(state, {
    coupons: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getCouponListError = (state, action) => {
  return update(state, {
    coupons: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const postGrantPremiumRequest = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const postGrantPremiumSuccess = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const postGrantPremiumError = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const postGrantPremiumManyRequest = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const postGrantPremiumManySuccess = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const postGrantPremiumManyError = (state, action) => {
  return update(state, {
    grantPremium: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

//Actionable Insight
const getActionableInsightRequest = (state, action) => {
  return update(state, {
    actionableInsight: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const getActionableInsightSuccess = (state, action) => {
  return update(state, {
    actionableInsight: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getActionableInsightError = (state, action) => {
  return update(state, {
    actionableInsight: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const postActionableInsightRequest = (state, action) => {
  return update(state, {
    createActionableInsight: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const postActionableInsightSuccess = (state, action) => {
  return update(state, {
    createActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const postActionableInsightError = (state, action) => {
  return update(state, {
    createActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchActionableInsightRequest = (state, action) => {
  return update(state, {
    updateActionableInsight: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchActionableInsightSuccess = (state, action) => {
  return update(state, {
    updateActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchActionableInsightError = (state, action) => {
  return update(state, {
    updateActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchOrderActionableInsightRequest = (state, action) => {
  return update(state, {
    orderActionableInsight: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchOrderActionableInsightSuccess = (state, action) => {
  return update(state, {
    orderActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchOrderActionableInsightError = (state, action) => {
  return update(state, {
    orderActionableInsight: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

//Key Concept
const getKeyConceptRequest = (state, action) => {
  return update(state, {
    keyConcept: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const getKeyConceptSuccess = (state, action) => {
  return update(state, {
    keyConcept: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getKeyConceptError = (state, action) => {
  return update(state, {
    keyConcept: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const postKeyConceptRequest = (state, action) => {
  return update(state, {
    createKeyConcept: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const postKeyConceptSuccess = (state, action) => {
  return update(state, {
    createKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const postKeyConceptError = (state, action) => {
  return update(state, {
    createKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchKeyConceptRequest = (state, action) => {
  return update(state, {
    updateKeyConcept: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchKeyConceptSuccess = (state, action) => {
  return update(state, {
    updateKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchKeyConceptError = (state, action) => {
  return update(state, {
    updateKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchOrderKeyConceptRequest = (state, action) => {
  return update(state, {
    orderKeyConcept: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchOrderKeyConceptSuccess = (state, action) => {
  return update(state, {
    orderKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchOrderKeyConceptError = (state, action) => {
  return update(state, {
    orderKeyConcept: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

//Top Carousel
const getTopCarouselRequest = (state, action) => {
  return update(state, {
    topCarousel: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const getTopCarouselSuccess = (state, action) => {
  return update(state, {
    topCarousel: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const getTopCarouselError = (state, action) => {
  return update(state, {
    topCarousel: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const postTopCarouselRequest = (state, action) => {
  return update(state, {
    createTopCarousel: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const postTopCarouselSuccess = (state, action) => {
  return update(state, {
    createTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const postTopCarouselError = (state, action) => {
  return update(state, {
    createTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchTopCarouselRequest = (state, action) => {
  return update(state, {
    updateTopCarousel: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchTopCarouselSuccess = (state, action) => {
  return update(state, {
    updateTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchTopCarouselError = (state, action) => {
  return update(state, {
    updateTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchOrderTopCarouselRequest = (state, action) => {
  return update(state, {
    orderTopCarousel: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchOrderTopCarouselSuccess = (state, action) => {
  return update(state, {
    orderTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchOrderTopCarouselError = (state, action) => {
  return update(state, {
    orderTopCarousel: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

//Video Bank
const patchVideoBankRequest = (state, action) => {
  return update(state, {
    updateVideoBank: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchVideoBankSuccess = (state, action) => {
  return update(state, {
    updateVideoBank: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchVideoBankError = (state, action) => {
  return update(state, {
    updateVideoBank: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};

const patchOrderVideoBankRequest = (state, action) => {
  return update(state, {
    orderVideoBank: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
    },
  });
};

const patchOrderVideoBankSuccess = (state, action) => {
  return update(state, {
    orderVideoBank: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};

const patchOrderVideoBankError = (state, action) => {
  return update(state, {
    orderVideoBank: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: [] },
    },
  });
};
export default handleActions(
  {
    [constants.CREATE_TRAINER_REQUEST]: createTrainerRequest,
    [constants.CREATE_TRAINER_SUCCESS]: createTrainerSuccess,
    [constants.CREATE_TRAINER_ERROR]: createTrainerError,

    [constants.GET_TRAINERS_REQUEST]: getTrainersRequest,
    [constants.GET_TRAINERS_SUCCESS]: getTrainersSuccess,
    [constants.GET_TRAINERS_ERROR]: getTrainersError,

    [constants.GET_COURSELIST_REQUEST]: getCoursesRequest,
    [constants.GET_COURSELIST_SUCCESS]: getCoursesSuccess,
    [constants.GET_COURSELIST_ERROR]: getCoursesError,

    [constants.CREATE_COURSE_REQUEST]: createCoursesRequest,
    [constants.CREATE_COURSE_SUCCESS]: createCoursesSuccess,
    [constants.CREATE_COURSE_ERROR]: createCoursesError,

    [constants.GET_CAROUSEL_DATA_REQUEST]: getcCarouselDataRequest,
    [constants.GET_CAROUSEL_DATA_SUCCESS]: getcCarouselDataSuccess,
    [constants.GET_CAROUSEL_DATA_ERROR]: getcCarouselDataError,

    [constants.GET_VIDEO_REQUEST]: getVidoesRequest,
    [constants.GET_VIDEO_SUCCESS]: getVidoesSuccess,
    [constants.GET_VIDEO_ERROR]: getVidoesError,

    [constants.UPLOAD_VIDEO_REQUEST]: createVideoRequest,
    [constants.UPLOAD_VIDEO_SUCCESS]: createVideoSuccess,
    [constants.UPLOAD_VIDEO_ERROR]: createVideoError,

    [constants.CREATE_QUESTION_REQUEST]: createQuestionRequest,
    [constants.CREATE_QUESTION_SUCCESS]: createQuestionSuccess,
    [constants.CREATE_QUESTION_ERROR]: createQuestionError,

    [constants.GET_VIDEOBANK_REQUEST]: getVideobankRequest,
    [constants.GET_VIDEOBANK_SUCCESS]: getVideobankSuccess,
    [constants.GET_VIDEOBANK_ERROR]: getVideobankError,

    [constants.ADD_ITEM_VIDEOBANK_REQUEST]: addItemTovideobankRequest,
    [constants.ADD_ITEM_VIDEOBANK_SUCCESS]: addItemTovideobankSuccess,
    [constants.ADD_ITEM_VIDEOBANK_ERROR]: addItemTovideobankError,

    [constants.GET_USER_LIST_REQUEST]: getUserListRequest,
    [constants.GET_USER_LIST_SUCCESS]: getUserListSuccess,
    [constants.GET_USER_LIST_ERROR]: getUserListError,

    [constants.GET_QUESTION_CATEGORY_REQUEST]: getQuestionCategoryRequest,
    [constants.GET_QUESTION_CATEGORY_SUCCESS]: getQuestionCategorySuccess,
    [constants.GET_QUESTION_CATEGORY_ERROR]: getQuestionCategoryError,

    [constants.UPDATE_QUESTION_REQUEST]: updateQuestionRequest,
    [constants.UPDATE_QUESTION_SUCCESS]: updateQuestionSuccess,
    [constants.UPDATE_QUESTION_ERROR]: updateQuestionError,

    [constants.CREATE_QUESTION_CATEGORY_SUCCESS]: createQuestionCategorySuccess,

    [constants.DELETE_QUESTION_CATEGORY_SUCCESS]: deleteQuestionCategorySuccess,

    [constants.UPDATE_QUESTION_CATEGORY_SUCCESS]: updateQuestionCategorySuccess,

    [constants.SELECT_QUESTION_CATEGORY_SUCCESS]: selectQuestionCategorySuccess,

    [constants.UPGRADE_USER_PREMIUM_REQUEST]: upgradeUserPremiumRequest,
    [constants.UPGRADE_USER_PREMIUM_SUCCESS]: upgradeUserPremiumSuccess,
    [constants.UPGRADE_USER_PREMIUM_ERROR]: upgradeUserPremiumError,

    [constants.UPGRADE_USER_DATA_REQUEST]: upgradeUserDataRequest,
    [constants.UPGRADE_USER_DATA_SUCCESS]: upgradeUserDataSuccess,
    [constants.UPGRADE_USER_DATA_ERROR]: upgradeUserDataError,

    [constants.CREATE_COUPON_REQUEST]: createCouponRequest,
    [constants.CREATE_COUPON_SUCCESS]: createCouponSuccess,
    [constants.CREATE_COUPON_ERROR]: createCouponError,

    [constants.GET_COUPON_LIST_REQUEST]: getCouponListRequest,
    [constants.GET_COUPON_LIST_SUCCESS]: getCouponListSuccess,
    [constants.GET_COUPON_LIST_ERROR]: getCouponListError,

    [constants.POST_GRANT_PREMIUM_REQUEST]: postGrantPremiumRequest,
    [constants.POST_GRANT_PREMIUM_SUCCESS]: postGrantPremiumSuccess,
    [constants.POST_GRANT_PREMIUM_ERROR]: postGrantPremiumError,

    [constants.POST_GRANT_PREMIUM_MANY_REQUEST]: postGrantPremiumManyRequest,
    [constants.POST_GRANT_PREMIUM_MANY_SUCCESS]: postGrantPremiumManySuccess,
    [constants.POST_GRANT_PREMIUM_MANY_ERROR]: postGrantPremiumManyError,

    //Actionable Insight
    [constants.GET_ACTIONABLE_INSIGHT_DATA_REQUEST]:
      getActionableInsightRequest,
    [constants.GET_ACTIONABLE_INSIGHT_DATA_SUCCESS]:
      getActionableInsightSuccess,
    [constants.GET_ACTIONABLE_INSIGHT_DATA_ERROR]: getActionableInsightError,

    [constants.POST_ACTIONABLE_INSIGHT_DATA_REQUEST]:
      postActionableInsightRequest,
    [constants.POST_ACTIONABLE_INSIGHT_DATA_SUCCESS]:
      postActionableInsightSuccess,
    [constants.POST_ACTIONABLE_INSIGHT_DATA_ERROR]: postActionableInsightError,

    [constants.PATCH_ACTIONABLE_INSIGHT_DATA_REQUEST]:
      patchActionableInsightRequest,
    [constants.PATCH_ACTIONABLE_INSIGHT_DATA_SUCCESS]:
      patchActionableInsightSuccess,
    [constants.PATCH_ACTIONABLE_INSIGHT_DATA_ERROR]:
      patchActionableInsightError,

    [constants.PATCH_ORDER_ACTIONABLE_INSIGHT_DATA_REQUEST]:
      patchOrderActionableInsightRequest,
    [constants.PATCH_ORDER_ACTIONABLE_INSIGHT_DATA_SUCCESS]:
      patchOrderActionableInsightSuccess,
    [constants.PATCH_ORDER_ACTIONABLE_INSIGHT_DATA_ERROR]:
      patchOrderActionableInsightError,

    //Key Concept
    [constants.GET_KEY_CONCEPT_DATA_REQUEST]: getKeyConceptRequest,
    [constants.GET_KEY_CONCEPT_DATA_SUCCESS]: getKeyConceptSuccess,
    [constants.GET_KEY_CONCEPT_DATA_ERROR]: getKeyConceptError,

    [constants.POST_KEY_CONCEPT_DATA_REQUEST]: postKeyConceptRequest,
    [constants.POST_KEY_CONCEPT_DATA_SUCCESS]: postKeyConceptSuccess,
    [constants.POST_KEY_CONCEPT_DATA_ERROR]: postKeyConceptError,

    [constants.PATCH_KEY_CONCEPT_DATA_REQUEST]: patchKeyConceptRequest,
    [constants.PATCH_KEY_CONCEPT_DATA_SUCCESS]: patchKeyConceptSuccess,
    [constants.PATCH_KEY_CONCEPT_DATA_ERROR]: patchKeyConceptError,

    [constants.PATCH_ORDER_KEY_CONCEPT_DATA_REQUEST]:
      patchOrderKeyConceptRequest,
    [constants.PATCH_ORDER_KEY_CONCEPT_DATA_SUCCESS]:
      patchOrderKeyConceptSuccess,
    [constants.PATCH_ORDER_KEY_CONCEPT_DATA_ERROR]: patchOrderKeyConceptError,

    //Top Carousel
    [constants.GET_TOP_CAROUSEL_DATA_REQUEST]: getTopCarouselRequest,
    [constants.GET_TOP_CAROUSEL_DATA_SUCCESS]: getTopCarouselSuccess,
    [constants.GET_TOP_CAROUSEL_DATA_ERROR]: getTopCarouselError,

    [constants.POST_TOP_CAROUSEL_DATA_REQUEST]: postTopCarouselRequest,
    [constants.POST_TOP_CAROUSEL_DATA_SUCCESS]: postTopCarouselSuccess,
    [constants.POST_TOP_CAROUSEL_DATA_ERROR]: postTopCarouselError,

    [constants.PATCH_TOP_CAROUSEL_DATA_REQUEST]: patchTopCarouselRequest,
    [constants.PATCH_TOP_CAROUSEL_DATA_SUCCESS]: patchTopCarouselSuccess,
    [constants.PATCH_TOP_CAROUSEL_DATA_ERROR]: patchTopCarouselError,

    [constants.PATCH_ORDER_TOP_CAROUSEL_DATA_REQUEST]:
      patchOrderTopCarouselRequest,
    [constants.PATCH_ORDER_TOP_CAROUSEL_DATA_SUCCESS]:
      patchOrderTopCarouselSuccess,
    [constants.PATCH_ORDER_TOP_CAROUSEL_DATA_ERROR]: patchOrderTopCarouselError,

    //Video Bank
    [constants.PATCH_VIDEO_BANK_DATA_REQUEST]: patchVideoBankRequest,
    [constants.PATCH_VIDEO_BANK_DATA_REQUEST]: patchVideoBankSuccess,
    [constants.PATCH_VIDEO_BANK_DATA_REQUEST]: patchVideoBankError,

    [constants.PATCH_ORDER_VIDEO_BANK_DATA_REQUEST]: patchOrderVideoBankRequest,
    [constants.PATCH_ORDER_VIDEO_BANK_DATA_SUCCESS]: patchOrderVideoBankSuccess,
    [constants.PATCH_ORDER_VIDEO_BANK_DATA_ERROR]: patchOrderVideoBankError,
  },
  initialState
);
