import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  getActionableInsightDataRequest,
  videoModalRequest,
  // getVideoRequest,
  deleteCarouselDataRequest,
  setModalDataRequest,
  postActionableInsightDataRequest,
  deleteActionableInsightDataRequest,
  patchActionableInsightDataRequest,
  patchOrderActionableInsightDataRequest,
} from "../redux/actions";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UploadImageModal from "../Components/UploadImageModal";
import Loader from "../Components/Loader";
import Error from "../Components/Error";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CarouselItem from "../Components/CarouselItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  itemWrapper: {
    marginBottom: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / -1%), 0px 1px 0px 0px rgb(0 0 0 / 1%)",
    background: "#fff",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 20,
  },
  deleteIcon: {
    cursor: "pointer",
  },
  createIcon: {
    cursor: "pointer",
    marginLeft: 10,
  },
  iconsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "5%",
  },
  textContentWrapper: {
    paddingRight: 20,
  },
  title: {
    fontSize: "1.2rem",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: "1.1rem",
    marginBottom: 5,
  },
  description: {
    fontSize: ".95rem",
    marginBottom: 5,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
}));

export default function ActionableInsight() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actionableInsight = useSelector(
    (state) => state.course.actionableInsight
  );

  const [imageList, setImageList] = useState([]);
  useMemo(() => {
    if (
      actionableInsight.data &&
      Array.isArray(actionableInsight.data) &&
      actionableInsight.data.length > 0
    ) {
      setImageList(actionableInsight.data);
    }
  }, [actionableInsight]);
  // const [itemToDelete, setItemToDelete] = useState("");
  // const isLoading = useSelector((state) => state.config.isLoading);

  useEffect(() => {
    // if (!videos.isSuccess) {
    //   dispatch(getVideoRequest());
    // }
    dispatch(getActionableInsightDataRequest());
  }, []);

  const handleOpenModal = () => {
    dispatch(videoModalRequest(true));
  };

  // const handleEdit = (item) => {
  //   dispatch(setModalDataRequest(item));
  //   handleOpenModal();
  // };

  const handleUploadActionableInsight = (data) => {
    // console.log(data);
    dispatch(postActionableInsightDataRequest(data.formdata));
  };

  const handleDeleteImage = (id) => {
    dispatch(deleteActionableInsightDataRequest({ _id: id }));
    // console.log("Delete image", id);
  };

  const handleChangeImageVisibility = (data) => {
    const newData = { _id: data._id, appearInCarousel: !data.appearInCarousel };
    dispatch(patchActionableInsightDataRequest(newData));
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list
    if (!destination) return;

    if (destination.index === source.index) return;

    // Reorder the items
    const reorderedItems = [...imageList];
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    const newOrder = reorderedItems.map((item, index) => {
      return { _id: item._id, sort_index: index };
    });

    dispatch(patchOrderActionableInsightDataRequest({ order: newOrder }));
    setImageList(reorderedItems);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.playlistTitle}>Actionable Insights</div>
      </div>
      <UploadImageModal handleUpload={handleUploadActionableInsight} />
      {actionableInsight.isLoading && <Loader variant="big" />}
      {actionableInsight.isError && (
        <Error message={actionableInsight.message} />
      )}
      {actionableInsight.isSuccess && imageList.length > 0 && (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={`actionable-insight-dropable`}>
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {imageList.map((item, index) => {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <CarouselItem
                                key={index}
                                data={item}
                                handleDelete={handleDeleteImage}
                                handleChangeVisibility={
                                  handleChangeImageVisibility
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </>
      )}

      <Fab
        size="small"
        onClick={handleOpenModal}
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
