import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TopCarousel from "./screens/TopCarousel";
import PlayList from "./screens/PlayList";
import Trainers from "./screens/Trainers";
import MiniDrawer from "./screens/Demo";
import QuestionBank from "./screens/QuestionBank";
import VideoBank from "./screens/VideoBank";
import ActionableInsight from "./screens/ActionableInsight";
import Users from "./screens/Users";
import Coupon from "./screens/Coupon";
import Login from "./screens/Login";
import { useSelector } from "react-redux";
import UserDashboard from "./screens/UserDashboard";
import SendNotification from "./screens/Notification";
import { Pdf } from "./screens/Pdf";
import UserChangePassword from "./screens/UserChangePassword";
import { ConnectedRouter } from "connected-react-router";
import history from "./History";
import Otp from "./screens/Otp";
import ForgotPassword from "./screens/ForgotPassword";
import ForceChangePassword from "./screens/ForceChangePassword";
import KeyConcept from "./screens/KeyConcept";

export default function AppNavigator() {
  const loginData = useSelector((state) => state.auth.loginData);
  const [currentUser, setCurrentUser] = useState([]);
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    setCurrentUser(currentUser);
  }, [loginData]);

  return (
    <ConnectedRouter history={history}>
      {currentUser && currentUser.authenticated ? (
        <MiniDrawer>
          <Switch>
            <Route exact path="/">
              <UserDashboard />
            </Route>
            <Route path="/programme">
              <PlayList />
            </Route>
            <Route path="/notification">
              <SendNotification />
            </Route>
            <Route path="/top-carousel">
              <TopCarousel />
            </Route>
            <Route path="/trainers">
              <Trainers />
            </Route>
            <Route path="/question-bank">
              <QuestionBank />
            </Route>
            <Route path="/actionable-insight">
              <ActionableInsight />
            </Route>
            <Route path="/key-concept">
              <KeyConcept />
            </Route>
            <Route path="/video-bank">
              <VideoBank />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/pdf">
              <Pdf />
            </Route>
            <Route path="/changePassword">
              <UserChangePassword />
            </Route>
            <Route path="/coupon">
              <Coupon />
            </Route>
          </Switch>
        </MiniDrawer>
      ) : (
        <>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/otp" component={Otp} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route
              path="/forceChangePassword"
              component={ForceChangePassword}
            />
          </Switch>
        </>
      )}
    </ConnectedRouter>
  );
}
